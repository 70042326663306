@use '../../styles/variables' as colors;

.tableClass {
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.8125rem;

  tr {
    td {
      border: solid 1px #efefef;
      border-style: none solid solid none;
      height: 80px;

      &:first-child {
        border-left-style: solid;
        border-right-style: none;
      }

      &:last-child {
        @apply items-center flex justify-end;
        padding-right: 13px;
      }

      &:not(:first-child, :last-child) {
        border-left: 0px;
        border-right: 0px;
      }
    }

    &:first-child {
      td {
        border-top-style: solid;

        &:first-child {
          border-top-left-radius: 3px;
        }

        &:last-child {
          border-top-right-radius: 3px;
        }
      }
    }

    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 3px;
        }

        &:last-child {
          border-bottom-right-radius: 3px;
        }
      }
    }
  }

  tbody {
    font-weight: 350;

    tr {

      &:hover {
        background-color: colors.$customColorBlueGray;
      }
      td, td > div {
        color: colors.$customColorDarkBlue60;
      }
      td {
        &:first-child {
          width: 68px;
        }
      }
    }
  }

  thead {
    th {
      &:first-child {
        padding-left: 1.5rem // 32px;
      }
    }
  }
}
