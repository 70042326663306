.headerFormClass {
  grid-area: header;
  gap: 1.5rem;
}

.newInstanceFormClass {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: auto 30%;
  grid-template-areas:
  'header aside'
  'type aside'
  'locations aside'
  'flavors aside'
  'images aside'
  'networks aside'
  'sshkeys aside'
  'ipv4s aside'
;
}

.asideSummaryClass {
  grid-area: aside;
  position: -webkit-sticky;
  position: sticky;
  margin-top: 22px;
  align-self: start;
}
