@use '../../styles/variables' as colors;
@use '../../styles/flex';

.ipsSectionClass {
  @extend %col;

  padding: 1rem 1.5rem;
  border-bottom: 0.0625rem solid colors.$customColorLightGray; // 1px
}

.ipv6SectionClass {
  @extend %col;

  padding: 1rem 2.16rem  0.69rem 1.5rem;
  border-bottom: 0.0625rem solid colors.$customColorLightGray; // 1px
}

.ipv6Class {
  @extend %row;

  align-items: center;
  justify-content: space-between;
  margin-top: 0.63rem;
}

.ipv6TextClass {
  margin-left: 0.62rem;
}

.ipv4sClass {
  @extend %col;

  max-height: 8.12rem;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 0.69rem/* 10px */;
}

.ipv4Class {
  @extend %row;

  align-items: center;
  justify-content: space-between;
  border-bottom: 0.0625rem solid colors.$customColorLightGray; // 1px
  padding: 0.81rem 0.54rem 0.56rem 0.62rem;
}

.ipv4IconSectionClass {
  @extend %row;

  column-gap: 0.5rem;
}