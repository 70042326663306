@use '../../../../../styles/variables' as colors;

.selectItemClass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 39px;
  cursor: pointer;
  width: 100%;
  font-weight: 500;

  font-size: 13px;
  padding: 0 16px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.itemClass {
  width: 200px;
  height: 119px;
  margin-top: 24px;
  margin-right: 16px;
  border-width: 1px;
  border-radius: 6px;
}

.dropdownBodyClass {
  min-width: 200px;
  width: max-content;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 500;
  max-height: 350px;
  overflow-x: scroll;
}

.dropdownBodyMultyClass > {
  div:first-child  {
    border-radius: 6px 6px 0px 0px;
  }
  div:last-child  {
    border-radius: 0px 0px 6px 6px;
  }
}

.openClass {
  display: block;
}

.dropdownItemClass {
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px 10px 16px;

  &:hover {
    cursor: pointer;
    background-color: #f6f7f9;
  }
}

.dropdownDefaultValueClass {
  color: rgba(0, 0, 0, 0.29);
}

.selectedVersionClass {
  background-color: colors.$customColorLightGreen;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.19px;
  color: white;

  &:hover {
    cursor: pointer;
    background-color: colors.$customColorLightGreen;
    color: white;
  }
}
