@use '../../../styles/variables' as colors;

%commondetailsClass {
  height: 42px;
  border-radius:  0.375rem; // 6px
  font-size: 0.875rem; // 14px
  padding: 0 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.validationClass {
  @extend %commondetailsClass;

  border: 2px solid colors.$customDarkRed;
  background-color: colors.$customLightRed;
  font-weight: 500;
  cursor: pointer;
}

.detailsClass {
  @extend %commondetailsClass;

  border: 1px solid colors.$customColorLightGray;
  background-color: white;
  font-weight: 350;

  &:hover {
    border: 1px solid colors.$customBorderGray4;
  }
}

.checkboxClass{
  height: 20px;
  width: 20px;
  margin: 0 20px;
  cursor: pointer;
  border: 1px solid colors.$customColorLightGray;
  border-radius: 0.125rem;
  color: colors.$primaryColorEasternBlue;
}

.listClass {
  margin-top: 0.13rem;
  border-radius: 0.375rem;
  border: 1px solid colors.$customColorLightGray;
  position: absolute;
  // width: 100%;
  background-color: white;
  cursor: pointer;
  // z-index: 10;
  padding: 0.5rem 0;
  max-height: 8rem;
  overflow-y: scroll;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.03);
}

.listItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 2.25rem;
  padding: 0rem 1rem;

  &:hover {
    height: 2.25rem;
    background-color: colors.$customGray;
  }
}
