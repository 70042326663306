.closeClass {
  width: 1.25rem;
  height: 1.25rem;
}

.closeClass:hover,
.closeClass:focus {
  cursor: pointer;
}

.portalModalBoxTitleClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom-width: 1px;

  padding: 1.125rem 1.5rem;

  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}
