.docsSectionClass {
  display: flex;
  flex-direction: row;
  height: 3rem;
  background: linear-gradient(150deg, #02061D -47.47%, #1F264D -44.18%);
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  justify-content: space-between;
  align-items: center;
}
