%row, .row {
  display: flex;
  flex-direction: row;
}

%col, .col {
  display: flex;
  flex-direction: column;
}

%all-items-center, .all-items-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
