@use '../../styles/variables' as colors;

%commonRule {
  border-radius: 0.375rem;
  border: 1px solid colors.$customColorLightGray;
}

.sectionWrapperClass {
  @extend %commonRule;
  height: 42px;
  margin-bottom: 0.3125rem;
  display: flex;
  align-items: center;
  background-color: colors.$customBgLightGray2;
}

.ipItemClass {
  @extend %commonRule;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0px 24px;
  font-weight: 500;
  font-size: 0.875rem; // 14px

  height: 42px;
}

// name section: input warning
.warningClass {
  border: 2px solid colors.$customDarkYellow;
  background-color: colors.$customLightYellow10;
}

.fakeSelectDisableState {
  align-items: center;
  height: 42px;
  border: 1px solid #ebebeb;
  border-radius: 0.375rem;
  background-color: colors.$customBgLightGray2;
  padding: 0.5rem 1rem;
  cursor: not-allowed;
}
