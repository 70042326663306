@use '../../../styles/variables.scss' as colors;

.portalModalBgClass {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 35%;
  bottom: 0;
  overflow: auto; /* Enable scroll if needed */
}

.portalModalBoxClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: colors.$customColorDarkBlue;
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
