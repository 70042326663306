// ----COLORS----

// blue
$primaryColorEasternBlue: #27A9BF;
$primaryColorEasternBlueBtnHover: #2CBBD3;
$customColorBlueGray: #F7F8FC;

// green
$customColorLightGreen: #7EE896;

//darkblue
$customColorDarkBlue: #02061D;
$customColorDarkBlue60: #02061D99;
$customColorDarkBlue80: #02061DCC;

$customColorDarkBlueBtnHover: #0F0F0F;

//darkblue2
$customColorDarkBlue2: #2D2F3E;

//black
$customColorBlack: #000000;

//white
$customColorWhite: #ffffff;
$customColorWhite50: #FFFFFF80;

// gray
$customGray: #F6F7F9;
$customBgLightGray2: #f9fafb;
$customGray3: #CFCFCF;
$customGray4: #F3F5F7;
$customGrayBtnHover: #EDF0F3;
$customGray5: #616161;
$customGray6: #e5e7eb;
$customColorLightGray: #efefef;
$customBlueGray120: #EFF1F8;
$customGrayColor5: #F1F1F1;

// old
$customTextBlack2withOpacity1: #3736361a;
$customTextBlack2withOpacity3: #3636364D;
$customTextBlack2withOpacity7: #363636B3;
$customTextBlack3: #343437;

// border
$customBorderLightGray2: #ebebeb;
$customBorderLightGray3: #e0e0e0;
$customBorderGray4: #DBDBDB;

// bg
$customBgLightGray: #f0f2f5;
$customBgLightGray2: #f9fafb;
$customBgLightGray3: #f6f7f9;

// red
$customDarkRed: #ec4d4d;
$customDarkRedBtnHover: #ED5454;
$customLightRed: #fdecec;

// yellow
$customDarkYellow: #ECAC4D;
$customLightYellow10: rgba(236, 172, 77, 0.10);
$customBgLightYellow20: rgba(236, 172, 77, 0.20);
$customBorderLightYellow30: rgba(236, 172, 77, 0.30);
