@use '../../styles/variables' as colors;
@use '../../styles/flex';

.wrapperClass {
  display: flex;
  color: white;
  width: 100%;
  padding: 11px 56px;
  border-bottom: 1px solid #efefef;
  height: 64px;
}

.headerClass {
  @extend %row;
  z-index: 1;
  width: 100%;
  align-items: center;
}

.userDataWrapperClass {
  position: relative;
  text-align: left;
}

.userDataClass {
  position: absolute;
  background-color: white;
  top: -0.8rem;
  right: 0;
  border-radius: 0rem 0rem 0.375rem 0.375rem;
  border: 1px solid var(--light-gray, #EFEFEF);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.03);
  min-width: 20.125rem;
}

.userIconClass {
  @extend %row;
  @extend %all-items-center;

  width: 42px;
  height: 42px;
  border: 1px solid colors.$customBorderLightGray2;
  border-radius: 50%;
  background-color: #f3f5f7;
}

.userInfoBlockWrapperClass {
  @extend %row;

  padding: 1.5rem 0 1.5rem 1rem;
  // border-bottom: 1px solid colors.$customColorLightGray;
}

.userDataTextBtnWrapperClass {
  @extend %col;

  align-items: flex-start;
  font-size: 0.8125rem; /* 13px */
  font-weight: 350;
  padding: 1rem 1.5rem;
}
