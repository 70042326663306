@use '../../styles/_flex';
@use '../../styles/variables' as colors;

.loginPageWrapper {
  @extend %row;

  background-color: #ffffff;
  height: 100dvh;
}

.loginPageLeftSide{
  @extend %col;

  justify-content: center;
  margin: 0 6.25rem;
}

.loginPageFormWrapper {
  background-color: #ffffff;
  padding: 2.63rem 1.5rem 8.44rem 1.5rem;
  border-radius: 0.375rem;
  width: 23.875rem;
}

.loginPageLeftSideLogoWrapper {
  width: 8rem; /* 128px */
  height: 2.625rem;
  margin-bottom: 2rem; /* 32px */
}

.loginPageFormClass {
  max-width: 328px;
}

.loginPageFormClassText {
  margin: 0.31rem 0 3.5rem;
}

.loginPageRigthSide{
  @extend %col;
  @extend %all-items-center;

  height: 100dvh;
  width: 100%;
  align-items: center;
  position: relative;
}

.loginPageRightSideLogoWrapperWrapper {
  position: absolute;
  display: grid;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  place-items: center;
  z-index: 1;
}

.loginPageRightSideLogoWrapper {
  height: 6rem;
  width: 16rem;
}

.loginPageRightSideVideoBg {
  background: linear-gradient(96deg, #090D28 -2.72%, rgba(29, 36, 74, 0.71) 83.81%);
  height: 100vh;
  width: 100%;
  position: absolute;
}

@media screen and (max-width: 1024px) and (min-width: 640px) {
  .loginPageWrapper{
    background: linear-gradient(168deg, #02061D -1.65%, #1F264D 136.54%);
  }
}

@media screen and (max-width: 1024px) and (min-width: 100px) {
  .loginPageRigthSide,
  .loginPageRightSideLogoWrapperWrapper,
  .loginPageRightSideLogoWrapper {
    display: none
  }

  .loginPageWrapper {
    justify-content: center;
  }

  .loginPageLeftSide {
    margin: 0 0.1rem;
  }
}
