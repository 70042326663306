.dialogPanelClass {
  min-width: 430px;
  background-color: white;
  border-radius: 5px;
  position: relative;
  transition-duration: .15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.dialogTitleClass {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  padding: 1.125rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  display: flex;
}
