.containerWrapperClass{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.contentListClass {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.contentListItemClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.contentListItemTextClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.contentListItemColorDotClass {
  width: 10px;
  height: 10px;
  margin-bottom: 0.1rem;
  margin-right: 0.5rem;
  border-radius: 9999px;
}
