@use '../../styles/variables' as colors;
@use '../../styles/statuses';
@use '../../styles/flex';

.sidebarClass {
  @extend %col;
  align-items: center;
  justify-content: space-between;

  width: 17.5rem;

  padding: 0 1.88rem .5rem 1.88rem;
  background: linear-gradient(168deg, #02061D -1.65%, #1F264D 136.54%);
}

.logoClass {
  margin: 0.5rem 4.75rem 3.44rem 3.44rem;
  width: 9rem;
  height: 3rem;
}

.navbarClass {
  @extend %col;

  justify-content: space-between;
  align-items: center;
}

.navlinkClass {
  @extend %row;

  padding: 0.94rem 1.88rem 0.88rem;
  margin-top: 10px;
  height: 2.875rem;

  &:hover {
    color: colors.$primaryColorEasternBlue;
  }
}

.activeClass {
  color: #fff !important;
  background-color: #FFFFFF1A;
  border-radius: 0.375rem;
}

.navIconLinkClass {
  width: 20px;
  margin-right: 1rem;
}

.externalLinksClass {
  margin-top: 2rem;
}

.navTitleLinkClass {
  &:hover {
    color: colors.$primaryColorEasternBlue;
  }
}

.statusScheduledClass {
  min-width: 241px;
  min-height: 48px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding: 9px 0 10px 16px;

  background-color: #FFFFFF0D;
  height: auto;
  padding: 6px 7px;
}

.statusScheduledIconBgClass {
  @extend %all-items-center;

  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.375rem;
  background-color: rgba(255, 255, 255, 0.12);
  margin-right: 6px;
}

.statusScheduledTextClass {
  @extend %statusTextContainer;
  color: rgba(255, 255, 255, 0.60);
  font-size: 11px;
}

.statusScheduledTextLinkClass {
  @extend %statusTextContainer;
  color: rgba(255, 255, 255, 0.80);
  font-size: 11px;
  text-decoration-line: underline;
  margin-top: 6px;
  margin-bottom: 11px;
  cursor: pointer;
}

.statusScheduledInfo {
  @extend %col;
}

//will have a resolution of lg, xl up to 1534px; after 1534px is a 2xl;
$layout-breakpoint-small: 1534px;

@media (max-width: $layout-breakpoint-small) {
  .sidebarClass {
    width: 5rem;
  }

  .logoClass {
    margin: 1rem 1.5rem 3.81rem 1.5rem;
    width: 4rem;
    height: 2rem;
  }

  .navbarClass {
    justify-content: center;
  }

  .navTitleLinkClass {
    display: none;
  }

  .navlinkClass {
    margin: 0.5rem 0.75rem;
    padding: 0rem 1.33rem;
  }

  .navIconLinkClass {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-right: 0;

    &:hover {
      color: colors.$primaryColorEasternBlue;
    }
  }

  .activeClass {
    margin: 0.5rem 0.75rem;
    padding: 0rem 1.33rem;
  }

  .statusScheduledInfo {
    display: none;
  }

  .statusScheduledClassGlXl {
    background: none;
    min-width: 3.5rem;
  }

  .statusTextContainerGlXl {
    width: 3.5rem;
  }

}
