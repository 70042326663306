@use '../../styles/variables' as colors;
@use '../../styles/flex';

%btn-shared-font {
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.21px;
  color: white;
}

%btn-shared {
  @extend %row;
  @extend %all-items-center;
  @extend %btn-shared-font;

  border: none;
  cursor: pointer;
  border-radius: 6px;
}

.bigButtonClass {
  height: 2.625rem;
  padding: 0.875rem 1.5rem;
  font-size: 0.875rem; // 14px
}

.smallButtonClass {
  height: 2rem;
  padding: 0.625rem 1.75rem;
  font-size: 0.75rem; // 12px
}

.primaryButtonClass {
  @extend %btn-shared;
  @extend %btn-shared-font;

  background-color: colors.$primaryColorEasternBlue;

  &:hover {
    background-color: colors.$primaryColorEasternBlueBtnHover;
  }
}

.secondaryButtonClass {
  @extend %btn-shared;

  background-color: colors.$customColorDarkBlue;

  &:hover {
    background-color: colors.$customColorDarkBlueBtnHover;
  }
}

.grayButtonClass {
  @extend %btn-shared;

  background-color: colors.$customGray4;
  color: colors.$customColorDarkBlue;

  &:hover {
    background-color: colors.$customGrayBtnHover;
  }
}

.redButtonClass {
  @extend %btn-shared;

  color: white;
  background-color: colors.$customDarkRed;


  &:hover {
    background-color: colors.$customDarkRedBtnHover;
  }
}

.whiteButtonWithIconAndTooltipClass {
  border-radius: 0.375rem;
  background-color: colors.$customGray4;
  width: 2.625rem;
  height: 2.625rem;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;

  &:hover {
    background-color: colors.$customColorBlueGray;
  }
}

.menuActionItemButtonClass {
  @extend %row;

  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-weight: 350;
  font-size: 0.8125rem;
  height: 40px;
  width: 100%;
  padding: 1rem 0;

  &:hover {
    background-color: colors.$customColorBlueGray;
    width: 100%;
  }
}

.onlyTextButtonClass {
  @extend %row;
  @extend %all-items-center;
  // align-items: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  // margin: 0 2.44rem;

  &:hover {
    color: colors.$primaryColorEasternBlue;
    cursor: pointer;
  }
}

.withoutStyleButtonClass {
  &:hover {
    color: colors.$primaryColorEasternBlue;
    cursor: pointer;
  }
}

.grayTabButtonClass {
  border-radius: 1.5625rem;
  background-color: colors.$customColorBlueGray;
  padding: 0.56rem 1rem 0.49rem 1rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    background-color: colors.$customBlueGray120;
  }
};

.disabledButtonClass {
  opacity: .5;

  &:hover {
    cursor: not-allowed;
  }
}
