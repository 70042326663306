.portalModalBgClass {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 9rem; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.portalModalBoxClass {
  background-color: white;
  border-radius: 5px;
  margin: auto;
}

.portalModalFormDefaultWidth {
  min-width: 40%;
  max-width: 80%;
};

.portalModalFormWidth {
  width: 466px;
};

.portalModalInfoBoxWidth {
  width: 450px;
};

.portalModalBmInfoWidth {
  width: 704px;
};
