@use '../../../../../styles/variables' as colors;

.tableClass {
  border-collapse: separate;
  border-spacing: 0 5px;
  width: 100%;
  text-align: center;

  [type=checkbox] {
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  [type=checkbox]:checked {
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: colors.$customColorLightGreen;

    &:focus, &:hover {
      background-color: colors.$customColorLightGreen;
    }
  }

  tbody {
    tr {
      @apply items-center border rounded-md;
      height: 56px;
      border-radius: 6px;

      td {
        border: solid 1px #efefef;
        border-style: solid;
        height: 56px;

        &:first-child {
          border-left-style: solid;
          border-right-style: none;
          padding-left: 24px;
          justify-content: flex-start;
          display: flex;
        }

        &:last-child {
          border-left-style: none;
        }

        &:not(:first-child, :last-child) {
          border-left: 0px;
          border-right: 0px;
        }

        &.selected {
          border: 1px solid colors.$customColorLightGreen;
          border-style: solid;

          &:first-child {
            border-left-style: solid;
            border-right-style: none;
          }

          &:last-child {
            border-left-style: none;
          }

          &:not(:first-child, :last-child) {
            border-left: 0px;
            border-right: 0px;
          }
        }
      }

      &:first-child {
        td, .selected {
          border-top-style: solid;

          &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }

      &:not(:first-child, :last-child) {
        td, .selected {
          border-top-style: solid;

          &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }

      &:last-child {
        td, .selected {
          border-top-style: solid;

          &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }
    }
  }
}

.switchClass {
  @apply inline-flex flex-shrink-0 cursor-pointer border border-transparent transition-colors duration-200 ease-in-out;
  position: relative;
  height: 17px;
  width: 26px;
  border-radius: 9px;

  span {
    @apply pointer-events-none inline-block transform rounded-full shadow ring-0 transition duration-200 ease-in-out;
    height: 15px;
    width: 15px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.tabsTitleClass {
  @apply text-sm font-bold border-b-2 border-customColorLightGreen pb-2;
  width: 178px;
}
