.titleClass {
  font-size: 11px;
  margin-bottom: 8px;
}

.gpuCountClass {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.17px;

  margin-left: 21px;
}
