@use '../../../../../styles/variables.scss' as colors;

.instanceTypeMoleculeClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding: 1rem 2rem;

  border-radius: 0.375rem;
  border: 1px solid colors.$customColorLightGray;

  background-color: colors.$customBgLightGray2;
  grid-area: type;
}

.instanceTypeAtomClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
