@use '../../../styles/variables' as colors;
@use '../../../styles/statuses';
@use '../../../styles/flex';

.statusIncidentClass {
  @extend %statusContainer;
  border: 1px solid rgba(236, 77, 77, 0.12);
  background-color: rgba(236, 77, 77, 0.10);
  margin-right: 1rem;
  align-items: center;
}

.statusIncidentTitleClass {
  @extend %statusTextContainer;
  color: #EC4D4D;
  font-size: 13px;
}

.statusIncidentTextClass {
  @extend %statusTextContainer;
  color: #C05050;
  font-size: 10px;
  text-decoration-line: underline;
}

.statusMaintenanceClass {
  @extend %statusContainer;
  border: 1px solid rgba(236, 172, 77, 0.12);
  background-color: rgba(236, 172, 77, 0.10);
  margin-right: 1rem;
  align-items: center;
}

.statusMaintenanceTitleClass {
  @extend %statusTextContainer;
  color: #ECAC4D;
  font-size: 13px;
}

.statusMaintenanceTextClass {
  @extend %statusTextContainer;
  display: flex;
  flex-direction: row;
  color: #C09350;
  font-size: 10px;
}

#blinkerDotBig {
  animation: circleDot 1.5s linear 0s infinite;
  transform-origin: center;
}

@keyframes circleDot {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  15% {
    transform: scale(0.3);
    opacity: 0.9;
  }
  25% {
    transform: scale(0.5);
    opacity: 0.8;
  }
  50% {
    transform: scale(0.7);
    opacity: 0.7;
  }
  75% {
    transform: scale(0.9);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}
