@use '../../styles/variables' as colors;

.notificationWrapperSpaceClass {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  right: 65px;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
}

.notificationWrapperClass {
  display: flex;
  flex-direction: column;
  // height: 60px;
  padding: 0rem 1.5rem;
  align-items: flex-end;
  pointer-events: none;
}

.notificationClass {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-left: 1rem; /* 16px */
  margin-top: 1rem; /* 16px */
}

.notificationContentWrapperClass {
  pointer-events: auto;
  min-width: 252px;
  max-width: 20rem; /* 320px */
  overflow: hidden;
  border-left-width: 6px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.12);
}

.iconWrapperClass {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19.5px;
  border-radius: 9999px;
  background-color: white;
}

.buttonClass {
  display: inline-flex;
  border-radius: 0.375rem; /* 6px */
  background-color: white;

  &:hover {
    color: gray;
  }
}

.greenBgClass {
  fill: colors.$customColorLightGreen;
}

.greenBClass {
  border-color: colors.$customColorLightGreen;
}

.redBgClass {
  fill: colors.$customDarkRed;
}

.redBClass {
  border-color: colors.$customDarkRed;
}

.blueBgClass {
  fill: #3b82f6;
}

.blueBClass {
  border-color: #3b82f6;
}

.yellowBgClass {
  fill: colors.$customDarkYellow;
}

.yellowBClass {
  border-color: colors.$customDarkYellow;
}
