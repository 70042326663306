@use '../../styles/variables.scss' as colors;
@use '../../styles/flex';

.asideSectionClass {
  @extend %col;

  border: solid 1px colors.$customColorLightGray;
  border-top: 0;
  background-color: colors.$customBgLightGray2;
  border-radius: 0rem 0rem 0.3125rem 0.3125rem ;
  color: colors.$customColorDarkBlue80;
  width: 21.75rem;
}

.asideHeaderClass {
  @extend %row;

  align-items: center;
  background-color: colors.$customColorBlueGray;
  padding-left: 1.5rem;
  border-radius: 0.3125rem 0.3125rem 0rem 0rem;
  height: 2.625rem;
  border: 1px solid colors.$customColorLightGray;
  color: colors.$customColorDarkBlue80;
  width: 21.75rem;
}
