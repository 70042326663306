@use '../../styles/variables' as colors;
@use '../../styles/focus';
@use '../../styles/flex';

%input-shared {
  font-size: 0.875rem; // 14px
  font-weight: 350;
  border-radius: 6px;
  border: 1px solid colors.$customBorderLightGray2;
  background-color: white;
  margin-top: 0.5rem; // 8px
  padding: 0 1rem;
}

%select-shared {
  @extend %row;
  justify-content: space-between;
  align-items: center;

  border-radius: 0.375rem; // 6px
  height: 42px;
  font-size: 0.875rem; // 14px
  font-weight: 350;
  border: 1px solid colors.$customBorderLightGray2;
  padding: 0 1rem;
  min-width: 15.875rem; // 254px
}

.customInputClass {
  @extend %input-shared;
  display: flex;
  justify-content: center;
  height: 42px;

  &:focus {
    @extend %focus-shared;
  }
}

input[autocomplete="off"]::-webkit-contacts-auto-fill-button,
input[autocomplete="off"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

input::placeholder {
  color: colors.$customGray3;
  font-size: 0.875rem; // 14px
  font-weight: 350;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.01px;
}

.validationClass {
  @extend %select-shared;

  border: 2px solid colors.$customDarkRed;
  background-color: colors.$customLightRed;
}

.customSelectClass {
  @extend %select-shared;

  background-color: white;
  cursor: pointer;

  &:hover {
    border: 1px solid colors.$customBorderGray4;
  }
}

.customSelectDisabledClass {
  @extend %select-shared;

  color: colors.$customGray3;
  background-color: colors.$customBgLightGray2;
  cursor: not-allowed;
}

.checkboxUncheckClass {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  border-radius: 2px;
  background-color: white;
  border: 1.5px solid colors.$customBorderLightGray2;
  cursor: pointer;

  &:checked {
    background-color: colors.$customColorLightGreen;
  }
}

.checkboxCheckClass {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  border-radius: 2px;
  border: none;
  background-color: colors.$customColorLightGreen;
  cursor: pointer;
}

[type='radio']{
  border-color: colors.$customGray6;
  cursor: pointer;
}

[type='checkbox']:checked,
[type='radio']:checked {
  background-color: colors.$customColorLightGreen;
  cursor: pointer;
}

[type='checkbox']:checked:hover, [type='radio']:checked:hover {
  background-color: colors.$customColorLightGreen;
  cursor: pointer;
}

[type='checkbox']:disabled, [type='radio']:disabled {
  width: 1.25rem;
  height: 1.25rem;
  background-color: colors.$customBgLightGray2;
  border: 1.5px solid colors.$customBorderLightGray2;
}

[type='checkbox']:disabled:hover, [type='radio']:disabled:hover {
  cursor: not-allowed;
}

[type='checkbox']:indeterminate,
// [type='radio']:indeterminate,
[type='checkbox']:indeterminate:focus,
// [type='radio']:indeterminate:focus
// [type='radio']:indeterminate:focus-visible,
[type='checkbox']:indeterminate:focus-visible {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  border-radius: 2px;
  border: none;
  background-color: colors.$customColorLightGreen;
  content: '-';
}

[type='checkbox']:indeterminate:hover {
  background-color: colors.$customColorLightGreen;
  cursor: pointer;
}
