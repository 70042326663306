@font-face {
  font-family: 'ABCFavoritExtended';
  src: local('ABCFavoritExtended'), url('ABCFavoritExtended-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ABCFavoritExtended';
  src: local('ABCFavoritExtended'), url('ABCFavoritExtended-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ABCFavoritExtended';
  src: local('ABCFavoritExtended'), url('ABCFavoritExtended-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ABCFavoritExtended';
  src: local('ABCFavoritExtended'), url('ABCFavoritExtended-Book.ttf') format('truetype');
  font-weight: 350;
  font-style: normal;
}

@font-face {
  font-family: 'ABCFavoritExtended';
  src: local('ABCFavoritExtended'), url('ABCFavoritExtended-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
