%statusContainer {
  min-width: 241px;
  min-height: 48px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding: 9px 0 10px 16px;
}

%statusTextContainer {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 165px;
}
