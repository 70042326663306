@use '../../styles/variables' as colors;

.alertClass {
  background-color: colors.$customLightYellow10;
  padding: 17px 0px;
  padding-right: 19px;
  padding-left: 24px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.2px;

  align-items: start;
  justify-content: start;
  border: 1px solid colors.$customBorderLightYellow30;
};

.successAlertClass {
  background-color: rgba(77, 236, 141, 0.12);
  border-radius: 3px;
  border: 1px solid colors.$primaryColorEasternBlue;
  font-weight: 500;
  font-size: 14px;
}
