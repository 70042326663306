@use '../../../../../styles/variables' as colors;

.itemClass {
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid colors.$customColorLightGray;
  height: 45px;
  width: 150px;
  color: colors.$customGray5;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
}

.currentItemClass {
  border: 1px solid colors.$customColorLightGreen;
  background-color: colors.$customBgLightGray2;
}
