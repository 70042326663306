@use '../../../styles/variables' as colors;

%select-shared {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.375rem; // 6px
  height: 42px;
  border: 1px solid colors.$customBorderLightGray2;
  padding: 0 1rem;
  min-width: 15.875rem; // 254px
}

.selectListClass {
  display: flex;
  flex-direction: column;
  margin-top: 0.13rem;
  border-radius: 0.375rem;
  border: 1px solid colors.$customColorLightGray;
  position: absolute;
  background-color: white;
  padding: 0.5rem 0;
  max-height: 8rem;
  overflow-y: scroll;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.03);
  width: 100%;
  z-index: 10;
}

.validationClass {
  @extend %select-shared;

  border: 2px solid colors.$customDarkRed;
  background-color: colors.$customLightRed;
}

.disabledClass {
  @extend %select-shared;

  color: colors.$customGray3;
  background-color: colors.$customBgLightGray2;
  cursor: not-allowed;
}

.selectClass {
  @extend %select-shared;

  background-color: white;
  cursor: pointer;

  &:hover {
    border: 1px solid colors.$customBorderGray4;
  }
}
