@use '../../../styles/variables' as colors;

.tagClass {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid colors.$customBorderLightGray2;
  background-color: colors.$customBgLightGray;
  width: 101px;
  margin: 0 5px 5px 0;
  padding: 4px 10px;
  border-radius: 4px;
}

.titleClass {
  font-size: 11px;
  margin-bottom: 8px;
}
