@use './flex';
@use './variables.scss' as colors;

// tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// fonts
// @import '../assets/fonts/Montserrat/montserrat.css';
// @import '../assets/fonts/Nunito/nunito.css';
@import '../assets/fonts/ABCFavoritExtended/ABCFavoritExtended.css';
//
@import-normalize;

* {
  font-family: 'ABCFavoritExtended', sans-serif;
  padding: 0;
  margin: 0;
  color: colors.$customColorDarkBlue;
  box-sizing: border-box;
}

label > p {
  margin-left: 0.85rem; //12px
  margin-bottom: 0.5rem; //8px
  font-size: 0.8125rem; // 13px
  font-weight: 500;
}

hr {
  height: 0.0625rem;
  background-color: colors.$customColorLightGray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


input[type="text"] {
  height: 2.625rem; //42px
  border-radius: .375rem; //6px
}

textarea {
  height: 5.875rem; //94px
  border-radius: 0.75rem; //12px
}

input[type="text"],
textarea {
  width: 100%;
  font-size: 0.875rem; //14px
  font-weight: 350;
  border: 1px solid colors.$customBorderLightGray2;
}

input[type="text"]:invalid,
textarea:invalid {
  border: 2px solid colors.$customDarkRed;
  background-color: colors.$customLightRed;
}

input[type="text"]:focus-visible,
textarea:focus-visible {
  @apply ring-transparent;
  border: 1px solid colors.$customBorderLightGray2;
  background-color: white;
  border-radius: 0.75rem; //12px
  border-width: 2px;
  border-color: rgb(126 232 150);
}


.text-customColorWhite50 {
  color: colors.$customColorWhite50;
}

.text-xxl{
  font-size: 1.375rem; //22px
}

.text-xxxl{
  font-size: 1.75rem; //28px
}

.customBoxShadowChartClass {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.10));
}

.selectListItemClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 2.25rem; //36px
  padding: 1rem;
  gap: 1rem;

  &:hover {
    background-color: colors.$customGray;
  }
}

.iconClass {
  stroke: #fff;
  width: 1.25rem; //20px
  height: 1.25rem; //20px
  margin-left: 0.625rem; //10px
}

.lightGrayWrapperClass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid colors.$customColorLightGray;
  min-height: 2.625rem; //42px
  padding-bottom: 0.88rem;
}
