@use '../../../../../styles/variables' as colors;

.modalBodyBmClass {
  padding: 1.5rem 2rem;
}

.modalDescriptonBmClass {
  margin-bottom: 2rem;
};

.modalInfoBoxBmClass {
  padding: 1rem 1.75rem;
  border-radius: 0.75rem;
  border: 1px solid colors.$customColorLightGray;
  background-color: colors.$customBgLightGray2;
}

.modalInfoBoxPositionClass {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modalBodyInfoTotalBmClass {
  padding: 0.2rem 0.3rem;
  border-radius: 0.5rem;
  background-color: colors.$customGray4;
  margin: 0.5rem;
}

.modalBodyInfoBmClass {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // grid-template-rows: repeat(2, 100px);
  grid-gap: 2.56rem;
  // grid-column-gap: 2.56rem;
  padding-top: 2.5rem;
  padding-left: 1.5rem;
  border-bottom: 1px solid colors.$customColorLightGray;
  margin-bottom: 1.25rem;
}

.modalBodyInfoItemBmClass  {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
