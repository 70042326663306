// add a style here only if it is common
// to other components that apply to the entire overview folder
@use '../../../styles/variables' as colors;

.grayWrapperSectionClass {
  min-height: 3rem;
  background-color: #FBFCFE;
  border-radius: 0.375rem;
  border: 1px solid colors.$customColorLightGray;
  justify-content: space-between;
  align-items: center;
}

.grayWrapperSectionContentClass {
  padding: 1rem 1.5rem;
}

.overviewGridTemplateClass {
  display: grid;

  row-gap: 0.62rem;
  column-gap: 1.25rem;

  grid-template-columns: repeat(2, 1fr);
}
