@use '../../styles/variables' as colors;

details summary {
  list-style: none;

  &::-webkit-details-marker {
    display: none;
  }
}

.menuSummaryClass {
  display: flex;
  width: 38px;
  height: 38px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  color: colors.$customGray;

  &:hover {
    background-color: colors.$customColorBlueGray;
  }
}

.menuContentClass {
  position: absolute;
  right: 0px;
  z-index: 10;
  margin-top: 0.5rem;
  width: 174px;
  transform-origin: top right;
  border-radius: 0.125rem;
  background-color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #ebebeb;
}
